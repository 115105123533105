<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="batchScheduling"
        >批量设置排课</el-button
      >
      <!-- <el-button class="filter-item" type="primary" @click="refcourses"
        >新增单个课时</el-button
      > -->
    </div>
    <div class="filter-container">
      <el-select
        v-model="listQuery.school_id"
        placeholder="请选择学校"
        style="width: 150px"
        class="filter-item"
        @change="
          (listQuery.school_year = null),
            (listQuery.college_id = null),
            (colleges = []),
            (listQuery.grade_id = null),
            (grades = []),
            (listQuery.major_id = null),
            (majorList = []),
            (listQuery.class_id = null),
            (classes = []),
            (listQuery.teacher_id = null),
            (teachers = [])
        "
      >
        <el-option
          v-for="item in schools"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-date-picker
        v-model="listQuery.school_year"
        type="year"
        class="filter-item"
        value-format="yyyy"
        placeholder="选择学年"
        :clearable="false"
        @change="
          (listQuery.college_id = null),
            (colleges = []),
            (listQuery.grade_id = null),
            (grades = []),
            (listQuery.major_id = null),
            (majorList = []),
            (listQuery.class_id = null),
            (classes = []),
            (listQuery.teacher_id = null),
            (teachers = [])
        "
      >
      </el-date-picker>

      <el-select
        v-model="listQuery.semester"
        placeholder="请选择学期"
        style="width: 150px"
        class="filter-item"
        @change="getCollegeList(listQuery.school_id)"
      >
        <el-option label="第一学期" :value="1" />
        <el-option label="第二学期" :value="2" />
      </el-select>

      <el-select
        v-model="listQuery.college_id"
        placeholder="请选择院系"
        style="width: 150px"
        class="filter-item"
        @change="
          getGradeList(),
            (listQuery.grade_id = null),
            (grades = []),
            (listQuery.major_id = null),
            (majorList = []),
            (listQuery.class_id = null),
            (classes = []),
            (listQuery.teacher_id = null),
            (teachers = [])
        "
      >
        <el-option
          v-for="(v, i) in colleges"
          :key="i"
          :label="v.name"
          :value="v.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.grade_id"
        placeholder="请选择年级"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="
          getmajorList(),
            (listQuery.major_id = null),
            (majorList = []),
            (listQuery.class_id = null),
            (classes = []),
            (listQuery.teacher_id = null),
            (teachers = [])
        "
      >
        <el-option
          v-for="item in grades"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.major_id"
        placeholder="请选择专业"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="
          getClassList(),
            (listQuery.class_id = null),
            (classes = []),
            (listQuery.teacher_id = null),
            (teachers = [])
        "
      >
        <el-option
          v-for="(v, i) in majorList"
          :key="i"
          :label="v.name"
          :value="v.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.class_id"
        placeholder="请选择班级"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
      >
        <el-option
          v-for="item in classes"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <!--  @change="getTeacherList()" -->

      <!-- <el-select
        v-model="listQuery.teacher_id"
        placeholder="请选择教师"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
      >
        <el-option
          v-for="item in teachers"
          :key="item.teacher.id"
          :label="item.teacher.name"
          :value="item.teacher.id"
        ></el-option>
      </el-select> -->
      <el-button class="filter-item" type="primary" @click="getList">
        查找
      </el-button>
    </div>

    <div class="color_tip">
      代课
      <div class="daike"></div>
      调课
      <div class="tiaoke"></div>
    </div>
    <div class="calendar">
      <div class="calendar_top">
        <div class="c_top_left">{{ year }}</div>
        <div class="c_top_right">
          <el-date-picker
            v-model="value1"
            type="date"
            placeholder="选择日期"
            @change="pickerChange"
            :clearable="false"
            style="margin-right: 20px"
          >
          </el-date-picker>
          <el-button-group>
            <el-button type="plain" @click="getTime(2)"> 上周 </el-button>
            <el-button type="plain" @click="getTime(0)"> 今天 </el-button>
            <el-button type="plain" @click="getTime(1)"> 下周 </el-button>
          </el-button-group>
        </div>
      </div>
      <el-divider style="margin: 10px 0"></el-divider>
      <div class="calendar_centont">
        <div class="calendar_c_top">
          <div style="margin-top: 28px">
            <div v-for="jj in classKnob" :key="jj.id" class="broadside">
              {{ jj.name }}
            </div>
          </div>
          <div v-for="i in week" :key="i.id" class="calendar_item-top">
            <div style="margin-bottom: 10px">
              {{ i.name }}&nbsp;&nbsp;&nbsp;{{ i.data }}
            </div>
            <div
              class="calendar_item-centont"
              v-for="kk in classKnob"
              :key="kk.id"
            >
              <div
                v-for="(item, key) in events"
                :key="key"
                v-if="kk.name == item.class_time_config.name"
              >
                <div
                  v-if="i.time == item.date"
                  class="calendar_tag"
                  :class="
                    item.type == 2
                      ? 'calendar_tag_d'
                      : item.type == 3
                      ? 'calendar_tag_t'
                      : ''
                  "
                >
                  <!-- <p>课节:{{ item.class_time_config.name }}</p> -->
                  <p>课程名称:{{ item.class_course.school_course.name }}</p>
                  <p>上课教师:{{ info.name }}</p>
                  上课地点:{{ item.classroom }}
                  <el-dropdown @command="handleCommand($event, item)">
                    <span class="el-dropdown-link">
                      <i class="el-icon-s-tools el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" :split-button="true">
                      <!-- <el-dropdown-item command="1">代课</el-dropdown-item>
                      <el-dropdown-item command="2">调课</el-dropdown-item> -->
                      <el-dropdown-item command="3">修改</el-dropdown-item>
                      <el-dropdown-item command="4">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="课程表安排"
      :visible.sync="centerDialogVisible"
      width="50%"
      center
    >
      <el-form
        ref="listQuery"
        :rules="listQueryRules"
        :model="{ listQuery: listQuery }"
        label-width="100px"
      >
        <el-form-item
          label="请先选择班级条件"
          :prop="'listQuery.major_id'"
          :rules="listQueryRules.major_id"
        >
          <el-select
            v-model="listQuery.school_id"
            placeholder="请选择学校"
            style="width: 150px; margin-right: 10px"
            class="filter-item"
            @change="
              getCollegeList(listQuery.school_id),
                (listQuery.school_year = null),
                (listQuery.college_id = null),
                (colleges = []),
                (listQuery.grade_id = null),
                (grades = []),
                (listQuery.major_id = null),
                (majorList = []),
                (listQuery.class_id = null),
                (classes = []),
                (listQuery.teacher_id = null),
                (teachers = [])
            "
          >
            <el-option
              v-for="item in schools"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-date-picker
            v-model="listQuery.school_year"
            type="year"
            class="filter-item"
            value-format="yyyy"
            style="margin-right: 10px; margin-bottom: 10px"
            placeholder="选择学年"
            :clearable="false"
            @change="
              (listQuery.college_id = null),
                (colleges = []),
                (listQuery.grade_id = null),
                (grades = []),
                (listQuery.major_id = null),
                (majorList = []),
                (listQuery.class_id = null),
                (classes = []),
                (listQuery.teacher_id = null),
                (teachers = [])
            "
          >
          </el-date-picker>

          <el-select
            v-model="listQuery.semester"
            placeholder="请选择学期"
            style="width: 150px; margin-right: 10px"
            class="filter-item"
            @change="getCollegeList"
          >
            <el-option label="第一学期" :value="1" />
            <el-option label="第二学期" :value="2" />
          </el-select>

          <el-select
            v-model="listQuery.college_id"
            placeholder="请选择院系"
            style="width: 150px; margin-right: 10px"
            class="filter-item"
            @change="
              getGradeList(),
                (listQuery.grade_id = null),
                (grades = []),
                (listQuery.major_id = null),
                (majorList = []),
                (listQuery.class_id = null),
                (classes = []),
                (listQuery.teacher_id = null),
                (teachers = [])
            "
          >
            <el-option
              v-for="(v, i) in colleges"
              :key="i"
              :label="v.name"
              :value="v.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="listQuery.grade_id"
            placeholder="请选择年级"
            style="width: 150px"
            class="filter-item"
            @change="
              getmajorList(),
                (listQuery.major_id = null),
                (majorList = []),
                (listQuery.class_id = null),
                (classes = []),
                (listQuery.teacher_id = null),
                (teachers = [])
            "
          >
            <el-option
              v-for="item in grades"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="listQuery.major_id"
            placeholder="请选择专业"
            style="width: 150px; margin-left: 10px"
            class="filter-item"
            @change="
              getClassList(),
                (listQuery.class_id = null),
                (classes = []),
                (listQuery.teacher_id = null),
                (teachers = [])
            "
          >
            <el-option
              v-for="(v, i) in majorList"
              :key="i"
              :label="v.name"
              :value="v.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="请选择班级"
          :prop="'listQuery.class_id'"
          :rules="listQueryRules.class_id"
        >
          <el-select
            v-model="listQuery.class_id"
            placeholder="请选择班级"
            style="width: 150px"
            class="filter-item"
            @change="getTeacherList()"
          >
            <el-option
              v-for="item in classes"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-form
        ref="courses"
        :rules="coursesRules"
        :model="{ courses: courses }"
        label-width="100px"
      >
        <el-form-item
          label="课程名称"
          :prop="'courses.school_course_id'"
          :rules="coursesRules.school_course_id"
        >
          <el-select
            v-model="courses.school_type_id"
            placeholder="请选择课程类型"
            style="width: 150px"
            class="filter-item"
            @change="getCourseList(courses.school_type_id, 1)"
          >
            <!-- @clear='clearSelect' -->
            <el-option
                v-if="info.college_id == 0"
                label="电竞课程"
                :value="1"
              />
              <el-option v-else label="普通课程" :value="2" />
          </el-select>
          <el-select
            v-if="courses.school_type_id"
            v-model="courses.school_course_id"
            placeholder="请选择课程"
            style="width: 150px; margin-left: 10px"
            class="filter-item"
            @change="
              getTeacherList1(courses.school_type_id, courses.school_course_id)
            "
          >
            <el-option
              v-for="item in coursesList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上课教师:" required>
          <!-- 选择上课的课程 -->
          <!-- <el-select
            v-model="courses.teacher_id"
            placeholder="请选择教师"
            style="width: 150px"
            class="filter-item"
            @change="changeLog"
          >
            <el-option
              v-for="item in teachers1"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select> -->
          <el-input
            style="width: 200px"
            v-model="info.name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="课时日期:"
          :prop="'courses.date'"
          :rules="coursesRules.date"
        >
          <el-date-picker
            v-model="courses.date"
            type="date"
            placeholder="选择日期"
            :clearable="false"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="时间段:">
          <div
            v-for="(classtime, key) in courses.class_time_config"
            :key="key"
            style="margin-bottom: 10px"
            class="flex"
          >
            <el-form-item
              :prop="'courses.class_time_config.' + key + '.timeQuantum'"
              :rules="coursesRules.timeQuantum"
            >
              <el-select
                v-model="classtime.timeQuantum"
                placeholder="请选择时间段"
                style="width: 150px"
                class="filter-item"
                @change="
                  getClassTimeConfig(classtime.timeQuantum),
                    (configs = []),
                    (classtime.class_time_config_id = null)
                "
              >
                <el-option label="上午" :value="1" />
                <el-option label="下午" :value="2" />
                <el-option label="晚上" :value="3" />
              </el-select>
            </el-form-item>
            <el-form-item
              :prop="
                'courses.class_time_config.' + key + '.class_time_config_id'
              "
              :rules="coursesRules.class_time_config_id"
            >
              <el-select
                v-model="classtime.class_time_config_id"
                placeholder="请选择某节课"
                style="width: 150px; margin-left: 10px"
                class="filter-item"
              >
                <el-option
                  v-for="v in configs"
                  :label="v.name"
                  :value="v.id"
                  :key="v.id"
                />
              </el-select>
            </el-form-item>
            <el-button
              v-if="classtime.isadd"
              type="primary"
              @click="addTimeQuantum(key)"
              style="margin-left: 10px"
              >添加</el-button
            >
            <el-button
              v-else
              type="danger"
              @click="delTimeQuantum(key)"
              style="margin-left: 10px"
              >删除</el-button
            >
          </div>
        </el-form-item>
        <el-form-item
          label="课室:"
          :prop="'courses.classroom'"
          :rules="coursesRules.classroom"
        >
          <el-input
            v-model="courses.classroom"
            placeholder="请输入课室"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="上课要求:"
          :prop="'courses.class_requirements'"
          :rules="coursesRules.class_requirements"
        >
          <el-input
            v-model="courses.class_requirements"
            type="textarea"
            style="width: 400px"
            :rows="4"
            placeholder="请输入上课要求"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- </div> -->
      <!-- <div class="add">
        <i class="el-icon-circle-plus-outline" @click="addChild"></i>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addKeshi" :loading="btnLoading">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="代课"
      :visible.sync="substituteCorrect"
      :close-on-click-modal="false"
    >
      <el-form
        ref="substitute"
        :rules="substituteRules"
        :model="substitute"
        label-width="120px"
      >
        <el-form-item label="上课教师" prop="core">
          <el-select
            v-model="form.teacher_id"
            placeholder="请选择教师"
            style="width: 150px; margin-left: 10px"
            class="filter-item"
          >
            <el-option
              v-for="item in teachers1"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="substituteCorrect = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">
          确定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="调课"
      :visible.sync="adjustment"
      :close-on-click-modal="false"
    >
      <el-form
        ref="substitute"
        :rules="substituteRules"
        :model="substitute"
        label-width="120px"
      >
        <el-form-item label="日期" prop="core">
          <el-date-picker
            v-model="listQuery2.date"
            type="date"
            placeholder="选择月"
            style="margin-left: 10px"
            value-format="yyyy-MM-dd"
            :clearable="false"
            @change="change"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="上课教师" prop="core">
          <span v-for="v in schedules" :key="v.id">
            <el-radio v-model="form2.schedule_id" :label="v.id"
              >{{ v.class_time_config.name }}
              {{ v.class_course.school_course.name }}
              {{ v.class_course.teacher.name }}</el-radio
            ></span
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="adjustment = false">取消</el-button>
        <el-button type="primary" @click="saveData1" :loading="btnLoading">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatTimeToStr } from "@/utils/data.js";
import request from "@/utils/request";
export default {
  data() {
    return {
      listQuery: {
        school_year: null,
        semester: null,
        department: null,
        grade_id: null,
        sclass_id: null,
        teacher_id: null,
        class_id: null,
        year: null,
        month: null,
        start_at: null,
        end_at: null,
      },
      thisWeek: [],
      week: [],
      classKnob: [
        { id: 1, name: "第1节" },
        { id: 2, name: "第2节" },
        { id: 3, name: "第3节" },
        { id: 4, name: "第4节" },
        { id: 5, name: "第5节" },
        { id: 6, name: "第6节" },
        { id: 7, name: "第7节" },
        { id: 8, name: "第8节" },
        { id: 9, name: "第9节" },
        { id: 10, name: "第10节" },
        { id: 11, name: "第11节" },
        { id: 12, name: "第12节" },
      ],
      year: "",
      value1: "",
      departments: [], // 院系
      sclass_s: [], //班级
      teachers: [], //教师
      professionals: [], //教师
      colleges: [],
      grades: [],
      classes: [],
      value: null,
      radio: 1,
      date: 1, //日期
      substitute: [], //调课列表
      events: [],
      substituteCorrect: false,
      adjustment: false,
      btnLoading: false,
      centerDialogVisible: false,
      courses: {
        id: null,
        school_type_id: null,
        school_id: null,
        class_time_config_ids: [],
        class_time_config: [
          {
            timeQuantum: null,
            isadd: true,
            class_time_config_id: null, //课节 差
          },
        ],
        date: null, //课时日期 差
        school_course_id: null, //课程id 差
        teacher_id: null, //教师id 差
        timeQuantum: null, //时间段 差
        classroom: null, //课时 差
        class_requirements: null, //上课要求 差
        courseType: null, //课程类别名称
        courseName: null, //课程名称
        class_id: null,
      },
      //课时列表
      configs: [],
      teacherList: [],
      course_type: null, // 课程类型
      course_detail: null, // 具体课程
      ampm: null, // 上课时间
      class_num: null, // 第几节课
      requirements: null, // 上课要求
      classroom: null, //课室
      coursesList: [], //课程列表
      //验证规则
      substituteRules: {
        name: [{ required: true, message: "教师不能为空", trigger: "change" }],
        college_id: [
          { required: true, message: "院系不能为空", trigger: "change" },
        ],
      },
      listQueryRules: {
        class_id: [
          { required: true, message: "请选择班级", trigger: "change" },
        ],
        major_id: [
          { required: true, message: "请选择专业", trigger: "change" },
        ],
      },
      coursesRules: {
        school_course_id: [
          { required: true, message: "请选择课程", trigger: "change" },
        ],
        teacher_id: [
          { required: true, message: "请选择教师", trigger: "change" },
        ],
        date: [
          { required: true, message: "请选择课时日期", trigger: "change" },
        ],
        timeQuantum: [
          { required: true, message: "请选择时间段", trigger: "change" },
        ],
        class_time_config_id: [
          { required: true, message: "请选择某节课", trigger: "change" },
        ],
        class_time_config: [
          {
            type: "array",
            required: true,
            message: "请选择某节课",
            trigger: "change",
          },
        ],
        classroom: [{ required: true, message: "请输入课室", trigger: "blur" }],
        class_requirements: [
          { required: true, message: "请输入上课要求", trigger: "blur" },
        ],
      },
      form: {
        teacher_id: null,
        schedule_id: null,
        school_course_id: null,
      },
      teachers1: [],
      listQuery2: {
        class_id: null,
        date: null,
      },
      schedules: [],
      majorList: [],
      form2: {
        id: null,
        schedule_id: null,
      },
      schools: [],
      info: {},
      time: 0,
      isedit: null,
    };
  },
  created() {
    this.getInfo();
    this.getTime(0);
    // this.getCollegeList();
    this.getSchoolList();
  },
  methods: {
    getInfo() {
      request({
        url: "/api/teacherend/admin/info",
        method: "get",
        params: {},
      }).then((response) => {
        this.info = response.data.user;
      });
    },
    refcourses() {
      this.courses = {
        id: null,
        school_type_id: null,
        school_id: null,
        class_time_config_ids: [],
        class_time_config: [
          {
            timeQuantum: null,
            isadd: true,
            class_time_config_id: null, //课节 差
          },
        ],
        date: null, //课时日期 差
        school_course_id: null, //课程id 差
        teacher_id: null, //教师id 差
        timeQuantum: null, //时间段 差
        classroom: null, //课时 差
        class_requirements: null, //上课要求 差
        courseType: null, //课程类别名称
        courseName: null, //课程名称
        class_id: null,
      };
      this.centerDialogVisible = true;
      this.isedit = 0;
    },
    pickerChange(e) {
      this.getTime(0, e);
    },
    getTime(time, chackData) {
      //time = 7下周2  -7上周1   0本周0
      if (time == 1) {
        this.time += 7;
      } else if (time == 2) {
        this.time -= 7;
      } else {
        this.time = 0;
      }
      let date = new Date(); //this.chaoZuoTime
      let currentFirstDate = new Date(date);
      if (chackData) {
        currentFirstDate = new Date(chackData);
      }
      let formatDate = function (date) {
        let year = date.getFullYear(); //年
        let month = date.getMonth() + 1; //月
        let day = date.getDate(); //日;
        let week = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"][
          date.getDay()
        ];
        //我需要的格式是  （周几 月.日） 这里看你们的需求
        return week + "-" + month + "-" + day + "-" + year;
      };

      let addDate = function (date, n) {
        date.setDate(date.getDate() + n);
        return date;
      };

      let setDate = function (date) {
        let week = date.getDay() - 1; //控制 日开头还是一开头
        date = addDate(date, week * -1);
        currentFirstDate = new Date(date);
        let weekList = [];
        for (let i = 0; i < 7; i++) {
          weekList[i] = formatDate(i == 0 ? date : addDate(date, 1));
        }
        return weekList;
      };
      let thisWeek = setDate(addDate(currentFirstDate, this.time)); //本周
      let list = [];
      thisWeek.forEach((item) => {
        let sp = item.split("-");
        this.year = sp[3] + "年" + sp[1] + "月";
        if (sp[2] < 10) {
          sp[2] = "0" + sp[2];
        }
        list.push({
          name: sp[0],
          data: sp[1] + "·" + sp[2],
          time: sp[3] + "-" + (sp[1] < 10 ? "0" + sp[1] : sp[1]) + "-" + sp[2],
        });
      });
      this.week = list;
      list = [];
      this.listQuery.start_at = this.week[0].time;
      this.listQuery.end_at = this.week[this.week.length - 1].time;
      if (this.listQuery.class_id || this.listQuery.teacher_id) {
        this.getList();
      }
    },
    delTimeQuantum(key) {
      this.courses.class_time_config.splice(key, 1);
    },
    addTimeQuantum(key) {
      this.courses.class_time_config[key].isadd = false;
      this.courses.class_time_config.push({
        timeQuantum: null,
        isadd: true,
        class_time_config_id: null, //课节 差
      });
    },
    getSchoolList() {
      request({
        url: "/api/teacherend/school/list",
        method: "get",
        params: {
          limit: 99999999999999,
        },
      }).then((response) => {
        this.schools = response.data.data;
      });
    },
    changeLog(e) {
      console.log(e);
      this.courses.teacher_id = e;
      this.$forceUpdate(); //强制更新
      console.log(this.courses, "=========");
    },
    clearSelect() {
      this.courses.school_type_id = null;
      this.courses.school_course_id = null;
      this.courses.teacher_id = null;
    },
    Today(e) {
      this.listQuery.year = formatTimeToStr(e, "yyyy");
      this.listQuery.month = formatTimeToStr(e, "MM");
      console.log(e);
      if (this.listQuery.class_id && this.listQuery.teacher_id) {
        this.getList();
      }
      // let newDta
      // console.log(newDta);
    },
    //单个课时
    addKeshi() {
      this.courses.class_id = this.listQuery.class_id;
      this.courses.semester = this.listQuery.semester;
      this.courses.class_time_config_ids = [];
      this.courses.school_id = this.listQuery.school_id;
      this.courses.class_time_config.forEach((item) => {
        this.courses.class_time_config_id = item.class_time_config_id;
        this.courses.class_time_config_ids.push(item.class_time_config_id);
      });
      let url = "";
      if (this.isedit == 0) {
        url = "/api/teacherend/schedule/oneAdd";
      } else {
        url = "/api/teacherend/schedule/oneStore";
      }
      this.$refs["courses"].validate((valid) => {
        this.$refs["listQuery"].validate((valid) => {
          if (valid) {
          } else {
            return;
          }
        });
        if (valid) {
          this.btnLoading = true;
          request({
            url: url,
            method: "post",
            data: this.courses,
          })
            .then((response) => {
              this.getList();
              this.$message({
                type: "success",
                message: "修改成功",
              });
              this.btnLoading = false;
              this.centerDialogVisible = false;
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    getClassTimeConfig(v) {
      request({
        url: "/api/teacherend/schedule/classTimeConfig",
        method: "get",
        params: {
          limit: 999999999999999,
          type: v,
          school_id: this.listQuery.school_id,
        },
      }).then((response) => {
        this.configs = response.data;
      });
    },
    getTeacherList1(v, school_course_id) {
      var url = null;
      let data = null;
      if (v == 1) {
        url = "/api/teacherend/teacher/eSportslist";
        data = {
          limit: 99999999999999,
          semester: this.listQuery.semester,
          school_id: this.listQuery.school_id,
          school_year: this.listQuery.school_year,
          college_id: this.listQuery.college_id,
          class_id: this.listQuery.class_id,
          is_select: 1,
        };
      } else {
        url = "/api/teacherend/teacher/list";
      }
      request({
        url: url,
        method: "get",
        params: data,
      }).then((response) => {
        this.teachers1 = response.data.data;
        console.log(this.teachers1);
        // this.teachers.find(item=>item)
      });
    },
    getCourseList(v, key) {
      var url = null;
      if (key) {
        this.coursesList = [];
        this.teachers1 = [];
        this.courses.school_course_id = null;
        this.courses.teacher_id = null;
      }
      let data = null;
      if (v == 1) {
        url = "/api/teacherend/course/eSportslist";
        data = {
          limit: 99999999999999,
          school_id: this.listQuery.school_id,
          school_year: this.listQuery.school_year,
          semester: this.listQuery.semester,
          // teacher_id: this.info.id,
        };
      } else {
        url = "/api/teacherend/course/list";
        data = {
          limit: 99999999999999,
          school_id: this.listQuery.school_id,
          school_year: this.listQuery.school_year,
          semester: this.listQuery.semester,
          college_id: this.listQuery.college_id,
        };
      }
      request({
        url: url,
        method: "get",
        params: data,
      }).then((response) => {
        this.coursesList = response.data.data;
        this.$forceUpdate(); //强制更新
      });
    },
    getmajorList() {
      request({
        url: "/api/teacherend/school/majorList",
        method: "get",
        params: {
          limit: 999999999,
          college_id: this.listQuery.college_id,
          grade_id: this.listQuery.grade_id,
          school_id: this.courses.school_id,
        },
      }).then((response) => {
        this.majorList = response.data.data;
      });
    },

    //调课代课  确认按钮
    saveData() {
      this.form.school_id = this.listQuery.school_id;
      request({
        url: "/api/teacherend/schedule/replaceCourse",
        method: "post",
        data: this.form,
      }).then((response) => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.substituteCorrect = false;
        this.form.teacher_ids = "";
        this.getList();
      });
    },
    saveData1() {
      this.form2.school_id = this.listQuery.school_id;
      request({
        url: "/api/teacherend/schedule/changeCourse",
        method: "post",
        data: this.form2,
      }).then((response) => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.adjustment = false;
        this.getList();
      });
    },
    //选择月
    change() {
      this.listQuery2.class_id = this.listQuery.class_id;
      this.listQuery2.school_id = this.listQuery.school_id;
      request({
        url: "/api/teacherend/schedule/list",
        method: "get",
        params: this.listQuery2,
      }).then((response) => {
        console.log(response);
        this.schedules = response.data;
        console.log(this.schedules);
      });
    },

    getTeacherList() {
      this.listQuery.teacher_id = "";
      request({
        url: "/api/teacherend/eduAdmin/courseList",
        method: "get",
        params: {
          limit: 99999999999999,
          school_id: this.listQuery.school,
          college_id: this.listQuery.college_id,
          class_id: this.listQuery.class_id,
          is_select: 1,
        },
      }).then((response) => {
        this.teachers = response.data;
        this.$forceUpdate(); //强制更新
      });
    },
    getClassList() {
      console.log(this.listQuery.college_id);
      request({
        url: "/api/teacherend/school/classList",
        method: "get",
        params: {
          limit: 9999999999999,
          ...this.listQuery
          // grade_id: this.listQuery.grade_id,
          // college_id: this.listQuery.college_id,
          // major_id: this.listQuery.major_id,
        },
      }).then((response) => {
        this.classes = response.data.data;
      });
    },
    getGradeList() {
      request({
        url: "/api/teacherend/school/gradeList",
        method: "get",
        params: {
          limit: 999999999999999,
          school_year: this.listQuery.school_year,
          school_id: this.listQuery.school_id,
        },
      }).then((response) => {
        this.grades = response.data.data;
      });
    },
    getList() {
      this.listLoading = true;
      if (!this.listQuery.class_id && !this.listQuery.teacher_id) {
        this.$message({
          message: "请先选择班级和教师再查询",
          type: "warning",
        });
        return;
      }
      request({
        url: "/api/teacherend/schedule/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        console.log(response);
        this.events = response.data;
        this.total = response.data.total;
        this.listLoading = false;
        this.$forceUpdate(); //强制更新
      });
    },
    getCollegeList(id) {
      request({
        url: "/api/teacherend/school/collegeList",
        method: "get",
        params: {
          limit: 999999999999999,
          school_id: this.listQuery.school_id,
          semester: this.listQuery.semester,
        },
      }).then((response) => {
        this.colleges = response.data.data;
      });
    },
    batchScheduling() {
      this.$router.push(`/schedule/batch`);
    },
    oneDel(id) {
      this.$confirm("此操作将删除该课程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/teacherend/schedule/del",
            method: "post",
            params: { id, school_id: this.listQuery.school_id },
          }).then(() => {
            // this.substituteCorrect = false;
            this.getList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 查找
    handlerCheck() {},
    handleCommand(e, item) {
      if (e == 1) {
        this.substituteCorrect = true;
        this.form.school_course_id = item.class_course.school_course_id;
        this.form.teacher_ids = item.class_course.teacher_id;
        var v = 2;
        if (item.class_course.school_course.college_id == 0) {
          v = 1;
        }
        this.getTeacherList1(v, this.form.school_course_id);
        this.form.schedule_id = item.id;
      } else if (e == 2) {
        this.form2.id = item.id;
        this.adjustment = true;
      } else if (e == 3) {
        // this.courses = null;
        this.isedit = 1;
        this.courses = JSON.parse(JSON.stringify(item));
        this.getClassTimeConfig(this.courses.class_time_config.type);
        if (item.class_course.school_course.college_id == 0) {
          this.courses.school_type_id = 1;
        } else {
          this.courses.school_type_id = 2;
        }
        this.courses.school_course_id =
          this.courses.class_course.school_course_id;
        this.getCourseList(this.courses.school_type_id);
        this.courses.teacher_id = this.courses.class_course.teacher_id;
        this.getTeacherList1(
          this.courses.school_type_id,
          this.courses.school_course_id
        );
        //修改回显时间段
        let config = this.courses.class_time_config;
        let timeQuantumList = [
          {
            timeQuantum: config.type,
            isadd: true,
            class_time_config_id: config.id,
          },
        ];
        this.courses.class_time_config = timeQuantumList;

        this.centerDialogVisible = true;
      } else if (e == 4) {
        this.oneDel(item.id);
      }
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.class_box {
  padding: 10px;
}
.flex {
  display: flex;
}
.color_tip {
  display: flex;
  align-items: center;
  .daike {
    margin-right: 20px;
    width: 15px;
    height: 15px;
    background: #e6a23c;
    margin-left: 20px;
  }
  .tiaoke {
    width: 15px;
    height: 15px;
    background: #67c23a;
    margin-left: 20px;
  }
}
.cell {
  padding: 20px 10px;
  border: 1px solid #dcdfe6;
  position: relative;
  margin-bottom: 10px;
  // .del {
  //   right: -10px;
  //   top: -10px;
  //   position: absolute;
  // }
  .row_1 {
    margin-bottom: 5px;
  }
}
.el-icon-error {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 28px;
}
.el-divider--horizontal {
  margin: 10px 0;
}
.add {
  display: flex;
  align-items: center;
  justify-content: center;
  // .add_img {
  //   width: 35px;
  //   height: 35px;
  // }
  .el-icon-circle-plus-outline {
    font-size: 35px;
  }
}
.calendar {
  .calendar_top {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
  }
  .calendar_centont {
    .calendar_c_top {
      display: flex;
      .calendar_item-top {
        flex: 1;
        text-align: center;
        &:first-child .calendar_item-centont {
          border-left: 1px solid #dcdfe6;
        }
        .calendar_item-centont {
          min-height: 130px;
          // max-height: 485px;
          // height: 500px;
          height: 130px;
          border: 1px solid #dcdfe6;
          // margin-top: 10px;
          // border-left: none;
          overflow: hidden;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
          .calendar_tag {
            color: #1890ff;
            background-color: #e8f4ff;
            border-color: #d1e9ff;
            border-radius: 4px;
            text-align: left;
            padding: 10px;
            margin: 10px;
            p {
              margin: 5px 0;
            }
          }
          .calendar_tag_d {
            background-color: #fdf6ec;
            border-color: #faecd8;
            color: #e6a23c;
          }
          .calendar_tag_t {
            background-color: #f0f9eb;
            border-color: #e1f3d8;
            color: #67c23a;
          }
        }
      }
    }
  }
}
.broadside {
  height: 130px;
  line-height: 130px;
  border: 1px solid #dcdfe6;
  // margin-top: 0px;
  text-align: center;
}
</style>
